exports.components = {
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publishers-js": () => import("./../../../src/pages/publishers.js" /* webpackChunkName: "component---src-pages-publishers-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-authors-listing-js": () => import("./../../../src/templates/authors/listing.js" /* webpackChunkName: "component---src-templates-authors-listing-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-books-kindle-discount-listing-js": () => import("./../../../src/templates/books/kindle-discount-listing.js" /* webpackChunkName: "component---src-templates-books-kindle-discount-listing-js" */),
  "component---src-templates-books-listing-js": () => import("./../../../src/templates/books/listing.js" /* webpackChunkName: "component---src-templates-books-listing-js" */),
  "component---src-templates-categories-listing-js": () => import("./../../../src/templates/categories/listing.js" /* webpackChunkName: "component---src-templates-categories-listing-js" */),
  "component---src-templates-publishers-listing-js": () => import("./../../../src/templates/publishers/listing.js" /* webpackChunkName: "component---src-templates-publishers-listing-js" */)
}

